import React from 'react';

import Sidebar from '../layout/sidebar';





function Home() {

  return (
    <>

      <Sidebar />


    </>
  )
}

export default Home